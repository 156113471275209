import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";
import { Select } from "clutch/src/Select/Select.jsx";
import TextInput from "clutch/src/TextInput/TextInput.jsx";
import type { TFunction } from "i18next";
import { t as gt } from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import {
  PerkTable,
  TraitsDisplay,
  WeaponDuplicateTable,
  WeaponSidebar,
} from "@/game-destiny2/components/DatabaseComponents.jsx";
import { ItemIcon } from "@/game-destiny2/components/ItemIcon.jsx";
import { Recoil, recoilValue } from "@/game-destiny2/components/Recoil.jsx";
import { WEAPON_SUB_CATEGORIES } from "@/game-destiny2/constants/category.mjs";
import { RARITY, RARITY_LIST } from "@/game-destiny2/constants/rarity.mjs";
import { STATS } from "@/game-destiny2/constants/stats.mjs";
import { GAME_SYMBOL_DESTINY2 } from "@/game-destiny2/definition.mjs";
import type { Mod, Stat, Weapon } from "@/game-destiny2/models/model-wiki.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import { DataTableExtSort, DataTableLoading } from "@/shared/DataTable.jsx";
import SortOrder from "@/shared/SortOrder.jsx";
import { WikiLayout } from "@/shared/Wiki.jsx";
import { useQuery, useQueryValues, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type Column = (typeof columns)[number]["slug"];
const statsList = [
  ["impact", STATS.impact],
  ["reload", STATS.reload],
  ["fireRate", STATS.fireRate],
  ["magSize", STATS.magSize],
] as const;
const columns = [
  {
    label: ["common:wiki.name", "Name"],
    slug: "name",
    col: {
      align: "left",
      primary: true,
    },
    row: (w: Weapon) => ({
      display: <ItemIcon item={w} title={w.label} />,
      value: w.label,
      link: `/destiny2/database/weapons/${w.referenceId}/${w.slug}`,
    }),
  },
  ...statsList.map(([slug, hash]) => ({
    label: hash,
    slug,
    col: {
      align: "right",
      isStat: true,
    },
    row: (w: Weapon) => ({
      display: w.stats[hash] ?? "-",
      value: w.stats[hash] ?? 0,
    }),
  })),
  {
    label: STATS.recoil,
    slug: "recoil",
    col: {},
    row: (w: Weapon) =>
      w.stats[STATS.recoil]
        ? {
            display: <Recoil value={w.stats[STATS.recoil]} />,
            value: recoilValue(w.stats[STATS.recoil]),
          }
        : {
            display: "-",
            value: 0,
          },
  },
  {
    label: ["common:wiki.rarity", "Rarity"],
    slug: "rarity",
    col: {},
    row: (w: Weapon, t: TFunction) => ({
      display: (
        <span style={{ color: RARITY[w.rarity].color }}>
          {t(...RARITY[w.rarity].label)}
        </span>
      ),
      value: RARITY[w.rarity].tier,
    }),
  },
] as const;

type FILTER = keyof typeof FILTERS;
const FILTERS = {
  category: {
    options: [
      {
        text: ["destiny2:wiki.allWeaponTypes", "All Weapon Types"],
        value: "ALL",
      },
      ...WEAPON_SUB_CATEGORIES.map((c) => ({
        text: c.label,
        value: c.slug,
        filterValue: c.referenceId,
      })),
    ],
    key: "subCategory",
  },
  rarity: {
    options: [
      {
        text: ["common:wiki.allRarities", "All Rarities"],
        value: "ALL",
      },
      ...RARITY_LIST.map((r) => ({
        text: r.label,
        value: r.slug,
        filterValue: r.referenceId,
      })),
    ],
    key: "rarity",
  },
} as const;

type BaseFilters = {
  nameFilter: string;
  setNameFilter(a: string): void;
  sortOptions: { text: Translation; value: Column }[];
  sortBy: Column;
  setSortBy(a: Column): void;
  sortDir: "ASC" | "DESC";
  setSortDir(a: "ASC" | "DESC"): void;
};
type FiltersProps =
  | (BaseFilters & { disabled?: never })
  | (Partial<BaseFilters> & { disabled: true });
// TODO: filters class,breaker type, slot, rarity, season?
function Filters({
  nameFilter,
  setNameFilter,
  sortOptions = [],
  sortBy,
  setSortBy,
  sortDir,
  setSortDir,
  disabled,
}: FiltersProps) {
  const { t } = useTranslation();
  const [catFilter, setCatFilter] = useQuery<string>("category", "ALL");
  const [rarityFilter, setRarityFilter] = useQuery<string>("rarity", "ALL");

  return (
    <div className="search-container">
      <TextInput
        disabled={disabled}
        placeholder={t("common:search", "Search")}
        onChange={(e) => setNameFilter(e.target.value)}
        defaultValue={nameFilter}
        Icon={SearchIcon}
      />
      <Select
        disabled={disabled}
        options={FILTERS.category.options}
        selected={catFilter}
        onChange={setCatFilter}
      />
      <Select
        disabled={disabled}
        options={FILTERS.rarity.options}
        selected={rarityFilter}
        onChange={setRarityFilter}
      />
      <div className="sort-container">
        <Select
          disabled={disabled}
          options={sortOptions}
          selected={sortBy}
          onChange={setSortBy}
        />
        <SortOrder
          disabled={disabled}
          sortOrder={sortDir}
          setSortOrder={setSortDir}
        />
      </div>
    </div>
  );
}

const WeaponsLanding = ({
  weapons,
  stats,
}: {
  weapons: Record<number, Weapon>;
  stats: Record<number, Stat>;
}) => {
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useQuery<string>("name", "");
  const [sortBy, setSortBy] = useQuery<Column>("sortBy", "rarity");
  const [sortDir, setSortDir] = useQuery<"ASC" | "DESC">("dir", "DESC");
  const filters = useQueryValues(Object.keys(FILTERS) as FILTER[]);

  const sortOptions = useMemo(
    () =>
      weapons && stats
        ? columns.map((c) => ({
            text:
              typeof c.label === "number"
                ? (["", stats[c.label].label] as const)
                : c.label,
            value: c.slug,
          }))
        : [],
    [stats, weapons],
  );

  const rows = useMemo(
    () =>
      weapons &&
      Object.values(weapons)
        .filter((w) => {
          for (const f of Object.keys(FILTERS)) {
            const filterSlug = filters[f];
            if (filterSlug) {
              const filterValue = FILTERS[f].options.find(
                (f) => f.value === filterSlug,
              )?.filterValue;
              if (w[FILTERS[f].key] !== filterValue) return false;
            }
          }
          return true;
        })
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((a) => columns.map((c) => c.row(a, t))),
    [filters, t, weapons],
  );

  if (!weapons || !stats)
    return (
      <WikiLayout
        gameSymbol={GAME_SYMBOL_DESTINY2}
        title={["common:wiki.weapons", "Weapons"]}
      >
        <div className="main">
          <Filters nameFilter={nameFilter} sortDir={sortDir} disabled />
          <DataTableLoading />
        </div>
      </WikiLayout>
    );

  return (
    <WikiLayout
      gameSymbol={GAME_SYMBOL_DESTINY2}
      title={["common:wiki.weapons", "Weapons"]}
    >
      <div className="main">
        <Filters
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          sortOptions={sortOptions}
          sortBy={sortBy}
          setSortBy={(c) => setSortBy(`${c}`)}
          sortDir={sortDir}
          setSortDir={setSortDir}
        />
        <DataTableExtSort
          cols={columns.map((c) => ({
            display:
              typeof c.label === "number"
                ? stats[c.label].label
                : t(...c.label),
            ...c.col,
          }))}
          rows={rows}
          searchCol={0}
          searchText={nameFilter}
          sortCol={columns.findIndex((c) => c.slug === sortBy)}
          sortDir={sortDir}
          setSelectedSortCol={(idx) => setSortBy(columns[idx].slug)}
          setSelectedSortDir={setSortDir}
        />
      </div>
    </WikiLayout>
  );
};

function WeaponView({
  weaponId,
  weapons,
  stats,
  plugLists,
  mods,
  lore,
}: {
  weaponId: string;
  weapons: Record<string, Weapon>;
  stats: Record<string, Stat>;
  plugLists: Record<string, readonly number[]>;
  mods: Record<string, Mod>;
  lore: Record<string, string>;
}) {
  const { t } = useTranslation();
  const weapon = weapons?.[weaponId];
  // TODO: loading state :(
  if (!weapon || !mods || !plugLists || !stats || !lore) return null;

  const traits = weapon.traits?.map((hash) => mods[hash]);
  return (
    <WikiLayout
      gameSymbol={GAME_SYMBOL_DESTINY2}
      title={weapon.label}
      imgSrc={`${appURLs.BUNGIE}/${weapon.iconUri}`}
    >
      <div className="sidebar">
        <WeaponSidebar {...weapon} statMap={stats} />
      </div>
      <div className="main">
        {(!!traits?.length || weapon.source) && (
          <Card className="flex column gap-4">
            {weapon.source && <p>{weapon.source}</p>}
            {!!traits?.length && (
              <>
                <h3 className="type-subtitle--bold">
                  {t("destiny2:wiki.traits", "Traits")}
                </h3>
                <TraitsDisplay traits={traits} />
              </>
            )}
          </Card>
        )}
        <WeaponDuplicateTable
          weapons={weapon.alternates?.map((hash) => weapons[hash])}
        />
        <PerkTable
          referenceId={weaponId}
          mods={mods}
          plugLists={plugLists}
          sockets={weapon.sockets}
          stats={stats}
        />
        {!!weapon.lore && (
          <Card className="flex column gap-4">
            <h3 className="type-subtitle--bold">
              {t("destiny2:wiki.lore", "Lore")}
            </h3>
            <p style={{ whiteSpace: "preserve-breaks" }}>{lore[weapon.lore]}</p>
          </Card>
        )}
      </div>
    </WikiLayout>
  );
}

function DatabaseWeapons() {
  const {
    parameters: [weaponId],
  } = useRoute();
  const {
    destiny2: {
      wiki: { weapons, stats, mods, plugLists, lore },
    },
  } = useSnapshot(readState);

  if (weaponId)
    return (
      <WeaponView
        weaponId={weaponId}
        weapons={weapons}
        stats={stats}
        plugLists={plugLists}
        mods={mods}
        lore={lore}
      />
    );

  return <WeaponsLanding weapons={weapons} stats={stats} />;
}

export default DatabaseWeapons;

export function meta([weaponId]) {
  const weapons = readState.destiny2.wiki.weapons;
  const weapon = weapons?.[weaponId];

  const weaponName = weapon ? weapon.label : gt("common:weapon", "Weapon");
  if (weaponId && (!weapons || weapon))
    return {
      title: [
        "destiny2:weaponSeo.title",
        "{{weaponName}} in Destiny 2: Complete Profile and Item Manager | Blitz",
        { weaponName },
      ],
      description: ["", weapon?.tagLine],
      subtitle: true,
    };

  return {
    title: ["destiny2:weaponLanding.title", "Destiny 2 Weapons"],
    description: [
      "destiny2:weaponLanding.desc",
      "Explore the most comprehensive Destiny 2 Weapons Database on Blitz.gg! Find up-to-date stats, tier lists, and guides for all active Destiny 2 weapons to enhance your gameplay.",
    ],
    subtitle: !IS_APP,
  };
}
